import React, { Component } from "react"
import { Row, Col, Modal } from "react-bootstrap"
import "../styles/main.scss"
import Fbdark from "../images/fb-dark.svg"
import Indark from "../images/in-dark.svg"
import Close from "../images/close.svg"
import {
  ALERT_MSG_ERROR,
  AUTH_TOKEN_KEY,
  DOMAIN_API,
  PARAM_ID,
  translate,
  URL_GETONE_ADVICE,
  URL_IS_AUTH
} from "../utils"
import axios from "axios"
import {
  FacebookShareButton,
  LinkedinShareButton,
  FacebookIcon,
  LinkedinIcon
} from "react-share";
import parse from 'html-react-parser';
import ModalAlert from "../components/modalAlert"

class Advice extends Component{
  state = {
    advice: null,
    showAlert: false,
    alertTitle: '',
    alertMessage: '',
  }

  componentDidMount() {
    this.getAdvice()
  }

  getAdvice = () => {
    if (this.props.location) {
      const query = new URLSearchParams(this.props.location.search);
      if (query.get(PARAM_ID) !== null) {
        const id = query.get(PARAM_ID);
        const token = localStorage.getItem(AUTH_TOKEN_KEY)
        const header = {
          headers: { Authorization: `Bearer ${token}` }
        };
        const data = {
          id: id,
        };
        axios.post(URL_GETONE_ADVICE,data,header)
          .then(res => {
            if (res.data) {
              console.log(res.data)
              this.setState({ advice: res.data })
            }
          })
          .catch(err => {
            if (err.response && err.response.data) {
              console.log(err.response.data);
              this.setState({
                showAlert: true,
                alertTitle: ALERT_MSG_ERROR,
                alertMessage: err.response.data.message,
              })
            }
            console.log(err)
          });

      }else {
        this.setState({
          showAlert: true,
          alertTitle: ALERT_MSG_ERROR,
          alertMessage: "id of advice not found.",
        })
      }
    }
  }

  render() {
    const {advice,showAlert,alertMessage,alertTitle} = this.state;
    if (advice) console.log(advice)


    return (
      <div className="modal-wrap">
        <Modal.Dialog>
          <Modal.Header
            className="modal-green-alpha"
            style={{
              backgroundImage: `url("${(advice)?DOMAIN_API()+advice.s2500x500:"https://source.unsplash.com/1920x400/?mountain"}")`,
              //backgroundImage: `url("https://api.enter-business.com/support@enter-business.com/s2500x500/3663567150fcd9b6aaa3caf0ad2edc63")`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              width: "100%",
              height: "auto",
              color: "white",
            }}
          >
            <a className="closeButton" href={'/feed'}>
              <img
                width={28}
                height={28}
                className="img-fluid"
                src={Close}
                alt=""
              />
            </a>
            <Modal.Title>
              <span className="title-tag d-inline">{translate("actu")}</span>
              <span className="d-block">
                {(advice)?advice.title:''}
            </span>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Row>
              <Col md="7" className="pr-5">
                <h3>{(advice)?advice.subTitle:""}</h3>
                <p style={{whiteSpace: 'pre-wrap'}}>{ advice ? parse(advice.description) : '' }</p>
                {/*<p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                  volutpat ipsum id bibendum commodo. Etiam tincidunt ante ut ante
                  dignissim viverra. Proin pharetra lectus vel augue elementum, at
                  egestas dui iaculis. Mauris ac sem odio. Vivamus id elit sed
                  tellus condimentum posuere. Etiam convallis ac ipsum non tempus.
                  Vivamus orci erat, elementum eget malesuada ut, fringilla ut
                  elit. Donec velit mi, malesuada vitae convallis eleifend,
                  molestie eget nulla.
                </p>
                <p>
                  Praesent pulvinar sollicitudin est in tristique. Nulla purus
                  leo, varius non pharetra nec, iaculis eu velit. Sed justo mi,
                  molestie sit amet nisl nec, cursus vestibulum sem. Nunc non
                  volutpat erat, nec mattis ex. Sed fermentum elit sem, id luctus
                  nisl fermentum ut. Aliquam efficitur ac massa in venenatis.
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
                  malesuada aliquet nulla, id sodales mi. Sed congue nunc sem, non
                  tincidunt velit lobortis eu. Donec eget est ac nibh vestibulum
                  laoreet eu aliquam dolor. Etiam venenatis mauris et malesuada
                  gravida. Sed interdum eros id sem fermentum, eu lacinia ligula
                  vulputate. Suspendisse egestas nisl porta quam tristique, at
                  aliquet diam ullamcorper. Sed euismod quam vitae faucibus
                  malesuada. Nullam nec auctor felis.
                </p>
                <p>
                  Curabitur a ligula euismod libero semper facilisis ut sit amet
                  diam. Nullam varius sed eros ac ultricies. Sed posuere ligula
                  vitae ligula semper ultrices. Pellentesque rutrum blandit magna,
                  mattis facilisis neque fringilla eget. Donec at rutrum mauris.
                  Suspendisse a cursus nisi, et egestas metus. Sed aliquet tellus
                  neque, at pulvinar leo dictum aliquam. Interdum et malesuada
                  fames ac ante ipsum primis in faucibus.
                </p>*/}
              </Col>
              <Col md="4" className="offset-md-1">
                <h3>Share</h3>
                <ul className="list-none m-0 ui-social-linline">
                <li>
                    <FacebookShareButton
                      url={'www.enter-business.com'}
                      quote={'message particulier'}
                      hashtag={'#ENTERBUSINESS'}>
                      <FacebookIcon size={25} round={false} />
                    </FacebookShareButton>
                  </li>
                  <li>
                    <LinkedinShareButton
                      url={'www.enter-business.com'}
                    >
                      <LinkedinIcon size={25} round={false} />
                    </LinkedinShareButton>
                  </li>
                </ul>
              </Col>
            </Row>
          </Modal.Body>
          {(showAlert)?
            <ModalAlert
              alertTitle={alertTitle}
              alertMessage={alertMessage}
              closeModal={() => this.setState({showAlert: false})}
            />:null
          }
        </Modal.Dialog>
      </div>
    )
  }

}

export default Advice